import React, { Component } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./style.css";

export default class MobileMenu extends Component {
  state = {
    isMenuShow: false,
  };

  menuHandler = () => {
    this.setState({
      isMenuShow: !this.state.isMenuShow,
    });
  };

  render() {
    const { isMenuShow } = this.state;
    return (
      <div>
        <div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
          <div className="logo2">
            <h2>
              <AnchorLink href="#home">
                {this.props.brideFirstName} & {this.props.groomFirstName}
              </AnchorLink>
            </h2>
          </div>
          <ul className="responsivemenu">
            <li>
              <AnchorLink href="#home" onClick={this.menuHandler}>
                Home
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#events" onClick={this.menuHandler}>
                Events
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#attire" onClick={this.menuHandler}>
                Attire
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#registry" onClick={this.menuHandler}>
                Registry
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#party" onClick={this.menuHandler}>
                Wedding Party
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#story" onClick={this.menuHandler}>
                Story
              </AnchorLink>
            </li>
            <li>
              <AnchorLink href="#contact" onClick={this.menuHandler}>
                Contact
              </AnchorLink>
            </li>
          </ul>
        </div>
        <div className="showmenu" onClick={this.menuHandler}>
          <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
      </div>
    );
  }
}
