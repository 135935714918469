import React from "react";
import { isDesktop, isMobile } from "react-device-detect";
import Sectiontitle from "../section-title";
import "./style.css";

const Story = (props) => {
  function Event(props) {
    const image = require("../../images/story/" + props.event.image);
    const imageClassName = isDesktop
      ? "story-img story-img-desktop"
      : "story-img";

    if (isMobile || props.index % 2 === 0) {
      return (
        <>
          <div className="col-lg-4 col-md-12 col-12 pr-n">
            <div className={imageClassName}>
              <img src={image} alt={props.event.image} />
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-12">
            <div className="story-text left-align-text">
              <h3>{props.event.title}</h3>
              <span className="date">{props.event.date}</span>
              <p>{props.event.details}</p>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="col-lg-8 col-md-12 col-12">
          <div className="story-text right-align-text">
            <h3>{props.event.title}</h3>
            <span className="date">{props.event.date}</span>
            <p>{props.event.details}</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-12 col-12 pl-n">
          <div className={imageClassName}>
            <img src={image} alt={props.event.image} />
          </div>
        </div>
      </>
    );
  }

  const events = props.events.map((event, index) => (
    <Event index={index} event={event} key={index} />
  ));

  return (
    <div id="story" className="story-area section-padding">
      <Sectiontitle section={"Our Love Story"} />
      <div className="container">
        <div className="story-wrap">
          <div className="row">{events}</div>
        </div>
      </div>
    </div>
  );
};

export default Story;
