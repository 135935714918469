import React from "react";
import { isMobile } from "react-device-detect";
import Sectiontitle from "../section-title";
import "./style.css";

import paypal from "../../images/registry/paypal.png";
import venmo from "../../images/registry/venmo.png";

const Registry = (props) => {
  function Gift(props) {
    const image = require("../../images/registry/" + props.gift.image);

    let venmoURL = `${props.venmo}?txn=pay&note=${encodeURIComponent(
      props.gift.title
    )}`;
    let paypalURL = props.paypal;
    if (props.gift.amount) {
      venmoURL += `&amount=${props.gift.amount}`;
      paypalURL += `/${props.gift.amount}`;
    }

    const paymentButtons = (
      <div className="payment">
        <a href={venmoURL} target="_blank" rel="noopener noreferrer">
          <img
            src={venmo}
            alt="venmo"
            style={{ width: "25%", maxWidth: "501px", minWidth: "150px" }}
          />
        </a>
        <a href={paypalURL} target="_blank" rel="noopener noreferrer">
          <img
            src={paypal}
            alt="paypal"
            style={{ width: "25%", maxWidth: "501px", minWidth: "150px" }}
          />
        </a>
      </div>
    );

    const details = props.gift.quantity ? (
      <p>
        Amount: ${props.gift.amount} Desired Quantity: {props.gift.quantity}
      </p>
    ) : null;

    if (isMobile || props.index % 2 === 0) {
      return (
        <div className="gift-wrap">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-12">
              <div className="gift-img">
                <img src={image} alt={props.gift.image} />
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-12">
              <div className="gift-text">
                <h3>{props.gift.title}</h3>
                <p>{props.gift.description}</p>
                {details}
                {paymentButtons}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="gift-wrap">
        <div className="row">
          <div className="col-lg-8 col-md-12 col-12">
            <div className="gift-text gift-text-2">
              <h3>{props.gift.title}</h3>
              <p>{props.gift.description}</p>
              {details}
              {paymentButtons}
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12">
            <div className="gift-img">
              <img src={image} alt={props.gift.image} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const gifts = props.gifts.map((gift, index) => (
    <Gift
      index={index}
      gift={gift}
      key={index}
      paypal={props.paypal}
      venmo={props.venmo}
    />
  ));

  return (
    <div id="registry" className="registry-section section-padding">
      <Sectiontitle section={"Registry"} />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p class="text-center">
              We want you to know that your presence at our wedding, along with
              your love and best wishes are the greatest gifts we could ask for!
              The time, effort, and expense of traveling to be with us in Maui
              is something we deeply appreciate, and we are so thrilled to be
              able to share this special moment in our lives with you.
            </p>
            <p class="text-center">
              As many of you know, we were lucky enough to be able to purchase
              our first home together last June. And, as you may or may not
              know, it is a home with only 931 square feet. Because we have so
              little space and have already collected many of the essential
              items a newlywed couple needs, we’ve decided to set up a registry
              to help us make our perfect honeymoon come true.
            </p>
            <p class="text-center">
              We are so excited to finally go on our dream vacation of staying
              in an over-water villa in Bora Bora! We appreciate you helping us
              make the most of this once in a lifetime experience.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="tabs-site-button">
              <div className="event-tabs">
                <div className="col-md-12 col-12">{gifts}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registry;
