import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from "../HomePage/index";
import Scrollbar from "../../components/scrollbar";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/" component={Homepage} />
          </Switch>
          <Scrollbar />
        </Router>
      </div>
    );
  }
}

export default App;
