import React from "react";

import config from "../../config.json";
import Events from "../../components/events";
import Footer from "../../components/footer";
import Navbar from "../../components/Navbar";
import People from "../../components/people";
import Saveday from "../../components/countdown";
import SimpleSlider from "../../components/hero";
import Story from "../../components/story";
import Registry from "../../components/registry";
import Contact from "../../components/contact";
import Attire from "../../components/attire";

const Homepage = () => {
  return (
    <div>
      <Navbar
        groomFirstName={config.groomFirstName}
        brideFirstName={config.brideFirstName}
      />
      <SimpleSlider
        date={config.weddingDate}
        title={config.title}
        subtitle={config.subtitle}
      />

      <Saveday date={config.weddingDate} />
      <Events events={config.weddingEvents} />
      <Attire />
      <Registry
        gifts={config.registry.gifts}
        paypal={config.registry.paypal}
        venmo={config.registry.venmo}
      />
      <People people={config.people} />
      <Story events={config.story} />
      <Contact
        brideEmail={config.contact.brideEmail}
        groomEmail={config.contact.groomEmail}
      />
      <Footer
        groomFirstName={config.groomFirstName}
        brideFirstName={config.brideFirstName}
      />
    </div>
  );
};

export default Homepage;
