import React from "react";
import { isMobile } from "react-device-detect";
import Sectiontitle from "../section-title";
import "./style.css";

const Events = (props) => {
  function Event(props) {
    const image = require("../../images/events/" + props.event.image);

    if (isMobile || props.index % 2 === 0) {
      return (
        <div className="event-wrap">
          <div className="row">
            <div className="col-lg-5 col-md-12 col-12">
              <div className="event-img">
                <img src={image} alt={props.event.image} />
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12">
              <div className="event-text">
                <h3>{props.event.title}</h3>
                <span>{props.event.date}</span>
                <span>{props.event.location}</span>
                <p>{props.event.description}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="event-wrap">
        <div className="row">
          <div className="col-lg-7 col-md-12 col-12">
            <div className="event-text event-text-2">
              <h3>{props.event.title}</h3>
              <span>{props.event.date}</span>
              <span>{props.event.location}</span>
              <p>{props.event.description}</p>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-12">
            <div className="event-img">
              <img src={image} alt={props.event.image} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const events = props.events.map((event, index) => (
    <Event index={index} event={event} key={index} />
  ));

  return (
    <div id="events" className="event-section section-padding">
      <Sectiontitle section={"Wedding Events"} />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="tabs-site-button">
              <div className="event-tabs">
                <div className="col-md-12 col-12">{events}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Events;
