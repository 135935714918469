import React from "react";
import Sectiontitle from "../section-title";
import "./style.css";

const Contact = (props) => {
  return (
    <div id="contact" className="contact-section section-padding">
      <Sectiontitle section={"Contact Us"} />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p class="text-center">
              Questions? Comments or concerns? Just want to say hi? You can
              reach us at{" "}
              <a href={"mailto:" + props.brideEmail}>{props.brideEmail}</a> or{" "}
              <a href={"mailto:" + props.groomEmail}>{props.groomEmail}</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
