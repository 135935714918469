import React from "react";
import Sectiontitle from "../section-title";

const Attire = (props) => {
  return (
    <div id="attire" className="attire-section section-padding">
      <Sectiontitle section={"Attire"} />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p class="text-center">Our wedding attire is Beach Semi-Formal.</p>
            <p class="text-center">
              For ladies: Semi-formal women’s beach wedding attire includes
              bright colors, lightweight fabrics, and semi-formal accessories.
              Mini, midi or maxi dresses, as well as jumpsuits or formal
              rompers, are all appropriate choices. The ceremony and reception
              will take place on grass, so please no pointy heels. Instead,
              consider wedges, block heels or flats.
            </p>
            <p class="text-center">
              For men: Semi-formal men’s beach wedding attire should involve
              khaki, linen, or seersucker pants and a button-down. While a
              jacket isn’t required, a breathable sport coat or unique blazer is
              a fun addition! No shorts please. Loafers, boat shoes, or other
              similar styles are appropriate footware options.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attire;
