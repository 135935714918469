import React, { Component } from "react";
import Slider from "react-slick";
import { Animated } from "react-animated-css";
import { isDesktop, isMobile } from "react-device-detect";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

class SimpleSlider extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: !isMobile,
      autoplay: isDesktop,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      pauseOnFocus: false,
      infinite: true,
      speed: 1250,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
    };
    const items = ["item1", "item2", "item3"].map((item, index) => (
      <div id="home" className={item} key={index}>
        <div className="container">
          <div className="slide-content">
            <div className="slide-subtitle">
              <h4>{this.props.subtitle}</h4>
            </div>
            <div className="slide-title">
              <h2>{this.props.title}</h2>
            </div>
            <div className="slide-text">
              <p>
                {new Date(this.props.date).toLocaleDateString("en-us", {
                  weekday: "long",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </p>
            </div>
            <Animated>
              <div className="animated-circle"></div>
            </Animated>
          </div>
        </div>
      </div>
    ));
    return <Slider {...settings}>{items}</Slider>;
  }
}

export default SimpleSlider;
