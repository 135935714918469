import React, { Component } from "react";
import Countdown from "react-countdown";
import "./style.css";

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return null;
  }

  return (
    <ul className="react-countdown">
      <li key="days">
        <p className="digit">{days}</p>
        <p className="text">days</p>
      </li>
      <li key="hours">
        <p className="digit">{hours}</p>
        <p className="text">hours</p>
      </li>
      <li key="minutes">
        <p className="digit">{minutes}</p>
        <p className="text">min</p>
      </li>
      <li key="seconds">
        <p className="digit">{seconds}</p>
        <p className="text">sec</p>
      </li>
    </ul>
  );
};
class Saveday extends Component {
  render() {
    return (
      <div className="saveday">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="countdownwrap">
                <Countdown date={this.props.date} renderer={renderer} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Saveday;
