import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "react-sticky-header/styles.css";
import MobileMenu from "../../components/MobileMenu";
import "./style.css";

export default function Navbar(props) {
  return (
    <div className="fixed-navbar">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="logo">
                <h2>
                  <AnchorLink href="#home">
                    {props.brideFirstName} & {props.groomFirstName}
                  </AnchorLink>
                </h2>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="header-menu d-lg-block d-none">
                <ul className="mobail-menu d-flex">
                  <li>
                    <AnchorLink href="#home">Home</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#events">Events</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#attire">Attire</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#registry">Registry</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#party">Wedding Party</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#story">Story</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#contact">Contact</AnchorLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12">
              <MobileMenu
                groomFirstName={props.groomFirstName}
                brideFirstName={props.brideFirstName}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
