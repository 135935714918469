import React from "react";
import Sectiontitle from "../section-title";

import "./style.css";

const People = (props) => {
  function Person(props) {
    const image = require("../../images/people/" + props.image);

    let wrapClassName = "wedding-party-wrap";

    if (props.index % 2 === 0) {
      wrapClassName += " wedding-party-wrap-2";
    }

    return (
      <div className="col-lg-3 col-md-6 col-sm-6 grid">
        <div className={wrapClassName}>
          <div className="wedding-party-img">
            <img src={image} alt={props.image} />
          </div>
          <div className="wedding-party-content">
            <h4>{props.name}</h4>
            <span>{props.title}</span>
          </div>
        </div>
      </div>
    );
  }

  const people = props.people.map((person, index) => (
    <Person
      index={index}
      name={person.name}
      title={person.title}
      image={person.image}
      key={index}
    />
  ));

  return (
    <section id="party">
      <div className="section-padding">
        <Sectiontitle section={"Wedding Party"} />
        <div className="container wedding-party-area">
          <div className="wedding-party-area-menu">
            <div>
              <div className="row">{people}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default People;
